<template lang="pug">
.h-screen.w-screen.bg-gray-100.flex.items-center
  .flex.flex-col.items-center.justify-center.p-5.text-gray-700(
    class="sm:container sm:mx-auto md:flex-row"
  )
    .max-w-md
      .text-5xl.font-dark.font-bold 404
      p.text-2xl.font-light.leading-normal(class="md:text-3xl")
        | {{ $t('notFound.title') }}
      p.mb-8
        | {{ $t('notFound.subtitle') }}
      router-link.px-4.inline.py-3.text-sm.font-medium.leading-5.shadow.text-white.transition-colors.duration-150.border.border-transparent.rounded-lg.bg-aspire-yellow-1(
        :to="{ name: 'home' }",
        tag="button",
        class="focus:outline-none focus:shadow-outline hover:bg-aspire-yellow-3"
      )
        | {{ $t('notFound.back') }}
    .max-w-lg.mt-10(class="md:mt-0")
      img.w-40(class="md:w-48", :src="require('img/404-error.svg')")
</template>

<script>
export default {};
</script>

<style scoped></style>
